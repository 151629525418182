import DatadogHandler from 'utils/datadog';
import { get } from 'lodash';
import SkRequest from './BaseRequest';
import { MutationBuilder } from '../queryBuilder';
import {
  FETCH_CENTRES_DRAFT_FOR_MANAGEMENT,
  FETCH_CENTRES_FOR_MANAGEMENT,
  FETCH_CENTRE_DOC_CATEGORIES,
  FETCH_CENTRE_DOCS,
  GET_CENTRE_LICENSE_DOCS,
} from './actionTypes';

import localstorage from '../../utils/localStorage';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  FIND_ALL_CENTRE_DRAFT_FOR_SCHOOL,
  FIND_ALL_CENTRE_FOR_SCHOOL,
  FIND_ALL_CENTRE_LIST_DRAFT_FOR_SCHOOL,
  FIND_ALL_CENTRE_LIST_FOR_SCHOOL,
  GET_CENTRE_ACTIVITY_LOGS,
  GET_CENTRE_DOC_CATEGORIES,
  GET_CENTRE_DOC_CONFIG,
  GET_CENTRE_DOCS,
  POST_CENTRE_DOCUMENTS,
} from '../models/centreManagementModel';
import { checkMSEnabled } from '../../utils';
import {
  centreSerivce,
  BROADCAST_ENDPOINT,
} from '../../utils/msEndpointConstants';

const _mMutationBuilder = new MutationBuilder();

_mMutationBuilder.setNewMutation('removeCentreForSchool', {});

const dispatchManagementCentreFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CENTRES_FOR_MANAGEMENT,
    key: 'managementCentres',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCentreLicenseDocuments = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: GET_CENTRE_LICENSE_DOCS,
    key: 'centreLicenseDocuments',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchManagementCentreDraftFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CENTRES_DRAFT_FOR_MANAGEMENT,
    key: 'managementCentresDraft',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCentreDocumentCategoriesFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CENTRE_DOC_CATEGORIES,
    key: 'centreDocumentCategories',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCentreDocumentsFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CENTRE_DOCS,
    key: 'centreDocuments',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const removeCentre = async (IDSchool, centreID) => {
  try {
    const data = await SkRequest({
      authToken: localstorage.getValue('userToken'),
      data: {
        query: _mMutationBuilder.getMutationAsString('removeCentreForSchool', {
          IDSchool,
          centreID,
        }),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    throw ex;
  }
};

export const fetchManagementCentres =
  (
    IDSchool,
    pagination = {},
    filter = {},
    fetchType = '',
    isBroadCast = false
  ) =>
  async (dispatch, getState) => {
    const isMSEnabled = checkMSEnabled(getState());
    dispatchManagementCentreFetch(dispatch);

    try {
      if (isMSEnabled && isBroadCast) {
        const _centreSerivce = centreSerivce();
        const response = await _centreSerivce.get(
          BROADCAST_ENDPOINT.GET_MS_CENTRE
        );

        const transformData = get(response, 'data.data', []).map(eachResp => ({
          ID: get(eachResp, 'id'),
          code: get(eachResp, 'code'),
          label: get(eachResp, 'label'),
        }));

        const msResponseData = {
          success: true,
          data: {
            findAllCentreForSchool: {
              data: transformData,
            },
          },
        };
        if (get(msResponseData, 'success')) {
          dispatchManagementCentreFetch(dispatch, false, msResponseData);
        }
        return msResponseData;
      }
      const data = await SkApolloRequest({
        params: {
          query:
            fetchType === 'LIST'
              ? FIND_ALL_CENTRE_LIST_FOR_SCHOOL
              : FIND_ALL_CENTRE_FOR_SCHOOL,
          variables: {
            schoolID: IDSchool,
            includeClosedCentres: true,
            pagination,
            filter,
          },
          type: 'query',
        },
      });

      if (data.success) {
        dispatchManagementCentreFetch(dispatch, false, data);
      } else {
        dispatchManagementCentreFetch(dispatch, false, null, data.error);
      }

      return data;
    } catch (ex) {
      DatadogHandler.addError(ex);
      DatadogHandler.sendLog(ex, {}, 'error');
      dispatchManagementCentreFetch(dispatch, false, null, {
        error: ex.message,
      });
      throw ex;
    }
  };

export const storeCentreLicenseDocuments = data => dispatch => {
  dispatchCentreLicenseDocuments(dispatch);
  try {
    dispatchCentreLicenseDocuments(dispatch, false, data);
    return true;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    dispatchCentreLicenseDocuments(dispatch, false, null, {
      error: ex,
    });
    return false;
  }
};

export const fetchManagementUpcomingCentres =
  (IDSchool, pagination = {}, filter = {}, fetchType = '') =>
  async dispatch => {
    dispatchManagementCentreDraftFetch(dispatch);
    try {
      const data = await SkApolloRequest({
        params: {
          query:
            fetchType === 'LIST'
              ? FIND_ALL_CENTRE_LIST_DRAFT_FOR_SCHOOL
              : FIND_ALL_CENTRE_DRAFT_FOR_SCHOOL,
          // query: FIND_ALL_CENTRE_FOR_SCHOOL,
          variables: {
            schoolID: IDSchool,
            pagination,
            filter,
          },
          type: 'query',
        },
      });
      if (data.success) {
        dispatchManagementCentreDraftFetch(dispatch, false, data);
      } else {
        dispatchManagementCentreDraftFetch(dispatch, false, null, data.error);
      }

      return data;
    } catch (ex) {
      DatadogHandler.addError(ex);
      DatadogHandler.sendLog(ex, {}, 'error');
      dispatchManagementCentreDraftFetch(dispatch, false, null, {
        error: ex.message,
      });
      throw ex;
    }
  };

export const fetchCentreDocumentCategories = () => async dispatch => {
  dispatchCentreDocumentCategoriesFetch(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CENTRE_DOC_CATEGORIES,
        type: 'query',
      },
    });

    if (data.success) {
      const mappedData = data?.data?.getCentreDocumentCategoriesConfig?.data;
      dispatchCentreDocumentCategoriesFetch(dispatch, false, mappedData);
    } else {
      dispatchCentreDocumentCategoriesFetch(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    dispatchCentreDocumentCategoriesFetch(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export const fetchCentreDocuments =
  (
    centreID,
    centreDocumentCategoriesIDs,
    page = 1,
    perPage = 10,
    activeSort = '-createdAt'
  ) =>
  async dispatch => {
    dispatchCentreDocumentsFetch(dispatch);
    try {
      const data = await SkApolloRequest({
        params: {
          query: GET_CENTRE_DOCS,
          variables: {
            centreID,
            centreDocumentCategoriesIDs,
            pagination: { page, perPage, sort: [activeSort] },
          },
          type: 'query',
        },
      });

      if (data.success) {
        const mappedData = data?.data?.listAllCentreDocumentsByCategories?.data;
        dispatchCentreDocumentsFetch(dispatch, false, mappedData);
      } else {
        dispatchCentreDocumentsFetch(dispatch, false, null, data.error);
      }

      return data;
    } catch (ex) {
      DatadogHandler.addError(ex);
      DatadogHandler.sendLog(ex, {}, 'error');
      dispatchCentreDocumentsFetch(dispatch, false, null, {
        error: ex.message,
      });
      throw ex;
    }
  };

export const getCentreDocConfig = reqData => async () => {
  try {
    return await SkApolloRequest({
      params: {
        mutation: GET_CENTRE_DOC_CONFIG,
        variables: reqData,
      },
      type: 'mutation',
    });
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return ex;
  }
};

export const getCentreActivityLogs =
  (centreID, page = 1, perPage = 10, activeSort = '-updatedAt') =>
  async () => {
    try {
      return await SkApolloRequest({
        params: {
          query: GET_CENTRE_ACTIVITY_LOGS,
          variables: {
            centreID,
            pagination: { page, perPage, sort: [activeSort] },
          },
        },
        type: 'query',
      });
    } catch (ex) {
      DatadogHandler.addError(ex);
      DatadogHandler.sendLog(ex, {}, 'error');
      return ex;
    }
  };

export const postCentreDocuments = uploadedCentreDocuments => async () => {
  try {
    return await SkApolloRequest({
      params: {
        mutation: POST_CENTRE_DOCUMENTS,
        variables: {
          uploadedCentreDocuments,
        },
      },
      type: 'mutation',
    });
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return ex;
  }
};

export default fetchManagementCentres;
